import React, { useState } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n/src';
import Banner from '../../components/Banner/Banner';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CardSection from '../../components/ContentType/Section/CardSection/CardSection';
import EngagementContainer from '../../components/EngagementContainer/EngagementContainer';
import PageBanner from '../../components/PageBanner/PageBanner';
import PopInVideoYtb from '../../components/PopIn/PopinVideoYtb/PopInVideoYtb';
import PopinVideo from '../../components/PopinVideo/PopinVideo';
import Seo from '../../components/Seo/Seo';
import Slider from '../../components/Slider/Slider';
import SliderBullet from '../../components/SliderBullet/SliderBullet';
import TextContent from '../../components/TextContent/TextContent';
import useMedias from '../../hooks/useMedias';
import Layout from '../../Layout';
import TitlePage from '../../Molecules/TitlePage/TitlePage';
import {
  youtube_parser,
  getBreadCrumb,
  formatBytes
} from '../../Utils/Utils';

import './styles.scss';
let classNames = require('classnames');

const SolutionsPage = ({ data }) => {
  const intl = useIntl();
  const { getImage, getDocument } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const documentsArray = data.allDocuments.edges;
  const pageData = data?.pageData?.edges[0]?.node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData?.path?.alias);
  const [videoId, setVideoId] = useState('');
  const [stopVideo, setStopVideo] = useState(false);
  //const [videoUrl, setVideoUrl] = useState(pageData?.field_video?.uri);
  const productTitleHtml = pageData?.field_title_formatted ? pageData?.field_title_formatted.processed.replace(/(<p[^>]+?>|<p>|<\/p>)/img, '') : pageData?.title;
  const metaTags = pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  let listArr = [];
  let documentDetails = getDocument(documentsArray, pageData?.relationships?.field_file?.drupal_internal__fid);

  const loadVideo = (val) => {
    setVideoId(youtube_parser(val));
    setStopVideo(false);
    document.body.classList.add('show_video_popin');
  };

  const closePopinVideo = () => {
    document.body.classList.remove('show_video_popin');
    setStopVideo(true);
    let iframes = document.querySelectorAll('iframe');
    Array.prototype.forEach.call(iframes, iframe => {
      iframe.contentWindow.postMessage(JSON.stringify({
        event: 'command',
        func: 'stopVideo'
      }), '*');
    });
    if (document.querySelector('a.accessibilite')) {
      document.querySelector('a.accessibilite').focus();
    }
  };

  return (
    <>
      <PopinVideo videoId={videoId} closePopinVideo={closePopinVideo} stopVideo={stopVideo} />
      <Layout imagesArray={imagesArray}>
        <Seo
          title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
          description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}

        />
        <>
          <div
            className={classNames(
              'page_template',
              'page_inner_solution'
            )}
          >

            <PageBanner
              visuel={getImage(
                imagesArray,
                pageData?.relationships?.field_image?.drupal_internal__mid
              )}
              scroll={true}
              reverse={true}
              hasVideo={pageData?.field_video?.uri ? true : false}
              loadVideo={loadVideo}
            >
              <div className="wrapper_page">
                <Breadcrumb
                  page_banner={true}
                  data={{
                    parentPage: {
                      title: breadCrumb?.parent?.name,
                      url: breadCrumb?.parent?.link,
                    },
                    currentPage: {
                      title: breadCrumb?.current?.name,
                      url: breadCrumb?.current?.link,
                    },
                    locale: pageData?.langcode,
                  }}
                />
                {
                  pageData?.field_title_formatted ? (
                    <TitlePage
                      color="color_white"
                      title={pageData?.field_title_formatted.processed.replace(/(<p[^>]+?>|<p>|<\/p>)/img, '')}
                      description={pageData?.field_subtitle}
                    />
                  ) : (
                    <TitlePage
                      color="color_white"
                      title={pageData?.title}
                      description={pageData?.field_subtitle}
                    />
                  )
                }

              </div>
            </PageBanner>
            <div className="background_white">
              <div className='solution_wrapper'>
                {
                  pageData?.relationships?.field_blocs?.map((block, i) => {
                    switch (block.__typename) {
                      case 'block_content__banner':

                        return (
                          <div key={i} />

                        );

                      case 'block_content__solutions':

                        return (

                          // block?.relationships?.field_solutions?.map((section,i) => (
                          <div key={i} >
                            <CardSection
                              imagesArray={imagesArray}
                              section={block}
                              counter={i}
                              listType='solutions'
                              nosSolution={false}
                              hasBg={block?.field_display_background ? true : false}
                              productTitle={pageData?.title}
                              productTitleHtml={productTitleHtml}
                            />
                          </div>
                          // ))

                        );

                      case 'block_content__slider':
                        return (
                          block.field_theme_bullets ? <SliderBullet
                            sliderBloc={block}
                            imagesArray={imagesArray}
                            prodName={productTitleHtml}
                            key={i}
                          />
                            :
                            <Slider
                              sliderBloc={block}
                              imagesArray={imagesArray}
                              key={i}
                              prodName={productTitleHtml}
                              loadVideo={loadVideo}
                            />
                        );

                      case 'block_content__engagements':
                        listArr = [];
                        block?.relationships?.field_engagement?.forEach(element => {
                          listArr.push({
                            icon: getImage(imagesArray, element?.relationships?.field_image?.drupal_internal__mid)?.image_style_uri?.gatsby_thumbnail,
                            text: element?.name
                          });
                        });
                        return (
                          <EngagementContainer engagements_list={listArr} EngagementTitle={block?.relationships?.field_title?.name} key={i} />
                        );

                      case 'block_content__texte':

                        return (
                          <TextContent block={block} productTitle={productTitleHtml} key={i} />
                        );
                    }
                  })
                }
              </div>
            </div>
          </div>
          <Banner title={intl.formatMessage({ id: 'sticky.contactUs' })} productTitle={pageData?.title} pdfLink={documentDetails?.uri?.url} size={formatBytes(documentDetails?.filesize)} contactUrl={allMenu.filter((i) => i.node?.menu_name === 'footer-nous-contacter')[0]?.node?.link?.url} />

        </>
        <PopInVideoYtb videoLink={pageData?.field_video?.uri} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query solutionsTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
  ) {
    pageData: allNodeSolutions(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
          field_description {
            processed
          }
          field_subtitle
          title
          field_title_formatted {
            processed
          }
          field_video {
            uri
          }
          field_metatag{
            title
	    description
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_file {
              drupal_internal__fid
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__slider {
                  field_theme_bullets
                  field_display_solution_name
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_title {
                      name
                    }
                    field_slides {
                      field_title {
                        processed
                      }
                      field_link {
                        title
                        url
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                      }
                      langcode
                    }
                  }
                }
                ... on block_content__solutions {
                  field_display_solution_name
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_title {
                      name
                    }
                    field_solution {
                      field_subtitle
                      field_cta
                      field_description {
                        processed
                      }
                      title
                      field_title_formatted { 
                        processed
                      }
                      field_lien{
                        uri															
                        url
                      }
                      path {
                        langcode
                        alias
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                      }
                    }
                  }
                  field_display_background
                }
                ... on block_content__engagements {
                  relationships {
                    field_title {
                      name
                    }
                    field_engagement {
                      name
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                      }
                    }
                  }
                }
                ... on block_content__texte {
                  field_display_logo
                  field_display_column
                  field_display_solution_name
                  field_display_as_main_title
                  field_display_background
                  body {
                    processed
                  }
                  relationships {
                    field_title {
                      name
                    }
                  }
                }
              }
            }
            field_image {
              drupal_internal__mid
            }
          }
        }
      }
    }

    allDocuments: allFileFile(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__fid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          filesize
          filename
          filemime
          uri {
            url
          }
          drupal_internal__fid
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }

  }
`;

export default SolutionsPage;

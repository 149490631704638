import React from 'react';

import './Banner.scss';
import { useLocation } from '@reach/router';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n/src';
import { DowloadIcon, ShareIcon, LetterIcon } from '../../Atoms/Icons/Icons';

function Banner({ title, productTitle, pdfLink, size, contactUrl }) {
  const intl = useIntl();
  const location = useLocation();
  var pdfLink = pdfLink.replaceAll("sites/colas_produits/files/produits", "solutions");

  return (
    <div className='banner-wrapper'>

      <div className="icon-wrapper">
        <a href={`mailto:?subject=${productTitle}&body=https:${pdfLink}`} >
          <div className='icon-wrap'>
            <ShareIcon customClass='bg_color' customfill='fill_color' />
            <div className="txt-wrap">
              <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'sticky.shareFile' }) }} />
            </div>
          </div>
        </a>
      </div>

      <div className="icon-wrapper download-wrap">
        <a href={pdfLink} target='_blank' rel="noreferrer">
          <div className='icon-wrap'>
            <DowloadIcon customClass='bg_color' customfill='fill_color' />
            <div className="txt-wrap">
              <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'sticky.downloadFile' }) }} />
              <span>{`(.pdf ${size})`}</span>
            </div>

          </div>
        </a>
      </div>
      <div className="icon-wrapper letter-wrap">
        <a href={contactUrl}>
          <div className='icon-wrap'>

            <LetterIcon customClass='bg_color' customfill='fill_color' />
            <div className="txt-wrap">
              <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'sticky.fillContact' }) }} />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Banner;
